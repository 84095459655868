import { Frame, useCycle } from "framer"

export function ChangeTextColor() {
    const [color, cycleColor] = useCycle("black", "orange")

    return (
        <Frame size={300} background={null} center onTap={() => cycleColor()}>
            <Frame
                size={"auto"}
                background={null}
                color={color}
                text={"Klikni na mňa!"}
                center
                onTap={() => cycleColor()}
            />
        </Frame>
    )
}
